import checkPass from 'jsModules/check-password-strength';
import getPages from 'jsUtils/dispatcher';
import init from 'jsModules';
import main from './inbox';
import Modal from 'jsModules/modal';
import setPageBgImage from 'jsModules/setpagebgimage';

const privateData = new WeakMap();

function getAutoLoad(INBOX, inx) {
    // Auto load these modules
    const autoLoad = {
        checkPassStrength(args) {
            const { checkPassStrength } = args.params;
            checkPass(checkPassStrength);
        },
        dialog(args) {
            const { dialog } = args.params;
            new Modal(dialog).show();
        },
        setBgImg(args) {
            setPageBgImage(inx.params.project.path.assets);
        }
    };

    return args => {
        // console.dir(args);
        const { params } = args;
        for (let prop in params) {
            if (Object.hasOwnProperty.call(params, prop)) {
                if (Object.hasOwnProperty.call(autoLoad, prop)) {
                    // console.info('Auto loading: %s', prop);
                    autoLoad[prop](args);
                }
            }
        }
    };
}

class Master {
    constructor(args) {
        const {
            pageName = null,
            params = {}
        } = args.login;

        privateData.set(this, {
            initialized: {}, // auto-loaded modules
            pageName,
            params
        });
    }

    autoLoad(modulesList) {
        modulesList(privateData.get(this));
    }

    pageLoad(list) {
        const {
            initialized,
            pageName: page,
            params
        } = privateData.get(this);

        if (page && Object.hasOwnProperty.call(list, page)) {
            list[page](params, initialized);
        }
    }
}

main.then(({ inx, INBOX }) => {
    // console.info('%cPage name: %s', "font-weight:bold", inx.login.pageName);

    const core = new Master(inx);
    // Run some widely used methods automatically
    try {
        core.autoLoad(getAutoLoad(INBOX, inx));
    } catch (error) {
        console.log('core autoload error ' + error);
    }

    // Load required page
    core.pageLoad(getPages(INBOX, inx));

    // On DOM ready
    init(inx);

}).catch((err) => {
    // console.warn(err);
});
