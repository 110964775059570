/* global inboxHtmlLoadTime */
import { getRandomInt } from 'tbSrc/js/utils';

export default (imgPref) => {
    let body         = document.querySelector("body"),
        imgUrl       = [imgPref, 'img/bg/', getRandomInt(1, 111), '.jpg'].join(''),
        htmlTag      = document.querySelector('html'),
        htmlOldStyle = htmlTag.style.cssText,
        htmlStyle    = 'background:url(\'' + imgUrl + '\') center no-repeat;background-size:cover';

    //do not load background image on slow internet (between html load and calling this code is more than 10 seconds)
    if (window.inboxHtmlLoadTime) {
        const dateDiff = Math.abs(Date.now() - inboxHtmlLoadTime)/1000;
        if (dateDiff > 6) {
            //http://projects.verou.me/css3patterns/#rainbow-bokeh
            const w = 'rgba(255,255,255,';
            htmlStyle = 'background:\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.15) 30%, ' + w + '.3) 32%, ' + w + '0) 33%) 0 0,\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.1) 11%, ' + w + '.3) 13%, ' + w + '0) 14%) 0 0,\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.2) 17%, ' + w + '.43) 19%, ' + w + '0) 20%) 0 110px,\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.2) 11%, ' + w + '.4) 13%, ' + w + '0) 14%) -130px -170px,\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.2) 11%, ' + w + '.4) 13%, ' + w + '0) 14%) 130px 370px,\n' +
                'radial-gradient(' + w + '0) 0, ' + w + '.1) 11%, ' + w + '.2) 13%, ' + w + '0) 14%) 0 0,\n' +
                'linear-gradient(45deg, #343702 0%, #184500 20%, #187546 30%, #006782 40%, #0b1284 50%, #760ea1 60%, #83096e 70%, #840b2a 80%, #b13e12 90%, #e27412 100%);\n' +
                'background-size: 470px 470px, 970px 970px, 410px 410px, 610px 610px, 530px 530px, 730px 730px, 100% 100%;\n' +
                'background-color: #840b2a;';
        }
    }

    if (htmlOldStyle.trim().length > 0) {
        htmlStyle = [htmlOldStyle, htmlStyle].join(';');
    }
    body.style.background = 'none transparent';
    htmlTag.setAttribute('style', htmlStyle);
};
