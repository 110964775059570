export default (INBOX, inx) => ({
    'login:index': (params, initObj) => {
        import(
            /* webpackChunkName: "login" */
            '../pages/login/'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },
    // // Password recovery: security question
    // 'phone:question': (params, initObj) => {
    //     import(
    //         /* webpackChunkName: "phone.question" */
    //         '../pages/phone/question'
    //     ).then(({ default: init }) => {
    //         init(inx, INBOX, params, initObj);
    //     });
    // },
    // Profile pages > overview
    'profile:overview': async (params) => {
        const { default: init } = await import(
            /* webpackChunkName: "profile.overview" */
            '../pages/profile/overview'
        );
        init(params, INBOX);
    },
    // Profile pages > Alternative logins
    'profile:altlogin': (params) => {
        import(
            /* webpackChunkName: "profile.altlogin" */
            '../pages/profile/altlogin'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    // Profile pages > Pass change
    'profile:pass-change': (params) => {
        import(
            /* webpackChunkName: "profile.phone.email" */
            '../pages/profile/pass-change'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    // Profile pages > Pass recovery > Recovery email
    'profile:phone:email': (params) => {
        import(
            /* webpackChunkName: "profile.phone.email" */
            '../pages/profile/phone/email'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    'profile:phone:form': (params, initObj) => {
        import(
            /* webpackChunkName: "phone.form" */
            '../pages/phone/form'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },

    'profile:phone:telegram': (params, initObj) => {
        import(
            /* webpackChunkName: "phone.telegram" */
            '../pages/phone/telegram'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },
    'profile:phone:user-sends-sms-to-us': (params, initObj) => {
        import(
            /* webpackChunkName: "phone.userSendsSmsToUs" */
            '../pages/phone/user-sends-sms-to-us'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },
    'profile:phone:sms-code': (params, initObj) => {
        import(
            /* webpackChunkName: "phone.weSendSmsToUser" */
            '../pages/phone/sms-code'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },
    // Profile pages > Pass recovery > Security question
    'profile:phone:question': (params) => {
        import(
            /* webpackChunkName: "profile.phone.question" */
            '../pages/profile/phone/question'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    // Profile pages > Pass recovery > 2 steps verifications > 1. step
    'profile:phone:two-steps_1': (params) => {
        import(
            /* webpackChunkName: "profile.phone.two-steps_1" */
            '../pages/profile/phone/two-steps_1'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    'profile:personal': async (params) => {
        const { default: init } = await import(
            /* webpackChunkName: "profile.personal" */
            '../pages/profile/personal'
        );
        init(params, INBOX);
    },
    'profile:required-name': async (params) => {
        const { default: init } = await import(
            /* webpackChunkName: "profile.required-name" */
            '../pages/profile/required-name'
        );
        init(params);
    },
    'profile:privacy': (params) => {
        import(
            /* webpackChunkName: "profile.personal" */
            '../pages/profile/privacy'
        ).then(({ default: init }) => {
            init(INBOX, params);
        });
    },
    'profile:delete': (params) => {
        import(
            /* webpackChunkName: "profile.delete" */
            '../pages/profile/delete'
        ).then(({ default: init }) => {
            init(params);
        });
    },
    // Profile > "Please enter your password"
    'profile:pre-auth': (params) => {
        import(
            /* webpackChunkName: "profile.pre-auth" */
            '../pages/profile/pre-auth'
        ).then(({ default: init }) => {
            init(inx.login.params);
        });
    },
    // mail sync
    'mail-sync': (params) => {
        import(
            /* webpackChunkName: "mail-sync" */
            '../pages/mail-sync'
        ).then(({ default: init }) => {
            init(inx.login.params);
        });
    },
    // mail sync autodiscovery
    'mail-sync-autodiscovery': (params) => {
        import(
            /* webpackChunkName: "mailsync.autodiscovery" */
            '../pages/mail-sync-autodiscovery'
        ).then(({ default: init }) => {
            init(inx.login.params);
        });
    },
    // mail sync status
    'mail-sync-status': (params) => {
        import(
            /* webpackChunkName: "mailsync.sync-status" */
            '../pages/mail-sync-status'
        ).then(({ default: init }) => {
            init(inx.login.params);
        });
    },
    // mail sync folder mapping
    'mail-sync-folder-mapping': (params) => {
        import(
            /* webpackChunkName: "mailsync.folder-mapping" */
            '../pages/mail-sync-folder-mapping'
        ).then(({ default: init }) => {
            init(inx.login.params);
        });
    },
    'sign-up': (params, initObj) => {
        import(
            /* webpackChunkName: "signup" */
            '../pages/signup/'
        ).then(({ default: init }) => {
            init(inx, INBOX, params, initObj);
        });
    },
    'billing:index': (params) => {
        import(
            /* webpackChunkName: "billing.index" */
            '../pages/billing/index'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    },
    'billing:invoice': (params) => {
        import(
            /* webpackChunkName: "billing.index" */
            '../pages/billing/invoice'
        ).then(({ default: init }) => {
            init(inx, INBOX, params);
        });
    }
});
