const alertTpl = ({ dismissible, hasIcon, text, type }) => {
    return `
        <div class="alert alert-${type} ${dismissible ? 'alert-dismissible' : ''} inx-alert ${hasIcon ? 'inx-alert_has-icon' : ''}" role="alert">
            ${dismissible ? '<button aria-label="Close" class="close ico ipi-close" data-bs-dismiss="alert" data-dismiss="alert" type="button"></button>' : ''}
            ${text}
        </div>`;
};

const
    defaults = {
        dismissible: true,
        hasIcon:     true,
        text:        'Alert message',
        type:        'info'
    },
    pd = new WeakMap();

export default class {
    constructor(container, prefs = {}) {
        pd.set(this, {
            alertTpl,
            containerEl: document.querySelector(container),
            opts:       { ...defaults, ...prefs}
        });
    }
    render(alertData) {
        let { containerEl, alertTpl, opts } = pd.get(this);

        containerEl.innerHTML = alertTpl({...opts, ...alertData});
        containerEl.querySelector('.close').addEventListener('click', function (e) {
            e.target.parentElement.remove();
        });

        pd.set(this, {
            ...pd.get(this),
            containerEl: containerEl
        });
        return pd.get(this, containerEl);
    }
    close() {
        const { containerEl } = pd.get(this);
        containerEl.innerHTML = '';
    }
    show(type, text) {
        this.render({ type, text });
    }
}
